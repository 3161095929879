import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import attention from "@static/images/404/attention.jpg";
import style from "./NotFound.module.scss";

export const NotFound = () => {
  const { t } = useTranslation("common");

  return (
    <div className="lk-wrap">
      <div className={style.main}>
        <div className={style.img}>
          <Image src={attention} alt="" />
        </div>
        <h1>{t("notFound.title")}</h1>
        <p>{t("notFound.subText")}</p>
        <p>{t("notFound.moreText")}</p>
        <Link href="/">{t("notFound.clickHere")}</Link>
      </div>
    </div>
  );
};
