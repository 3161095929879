import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NotFound } from "@components/pages/404/NotFound";
import { Container } from "@components/ui";
import { MainLayout } from "@layouts/MainLayout/MainLayout";

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, [
        "common",
        "header",
        "footer",
      ])),
    },
  };
};

const NotFoundPage = () => {
  return (
    <Container>
      <NotFound />
    </Container>
  );
};

NotFoundPage.layout = MainLayout;
export default NotFoundPage;
