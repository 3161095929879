import { Header, LoadingPage, ModalUI } from "@components/commun";
import cn from "clsx";
import { ErrorBoundary } from "@components/commun/ErrorBoundary/ErrorBoundary";
import { Footer } from "@components/commun/Footer/Footer";
import { Notification } from "@components/commun/Notification/Notification";
import {
  useGlobalMessaging,
  useRedirectIfSubscribed,
  useScript,
  useUI,
} from "@middleware/hooks";
import { IMessage, LayoutProps } from "@middleware/types";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { URL_PAGE_BILAN } from "@middleware/constants";
import MotivationScoreBar from "@components/commun/MotivationScoreBar/MotivationScoreBar";

export const MainLayout: LayoutProps = ({ children }) => {
  const { setMessage, cleanMessage, message } = useGlobalMessaging();
  const { displayDropdown, closeDropdown } = useUI();
  const { t } = useTranslation();
  const router = useRouter();
  const isFullScreenPage = router.pathname.includes(URL_PAGE_BILAN);

  useRedirectIfSubscribed();
  useScript("/static/js/cookies.js");

  return (
    <div className={cn("body", { isOpen: displayDropdown })}>
      <Header isFullScreenPage={isFullScreenPage} />
      <MotivationScoreBar />
      <LoadingPage />
      <Notification message={message} clear={cleanMessage} />
      <ErrorBoundary
        translation={t}
        setErreur={(_message: IMessage) => setMessage(_message)}
      >
        <main
          onClick={closeDropdown}
          className="super-main"
          role="presentation"
        >
          {children}
        </main>
      </ErrorBoundary>
      <Footer isFullScreenPage={isFullScreenPage} />
      <ModalUI />
    </div>
  );
};
